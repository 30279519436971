import firebase_app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/remote-config';

const firebaseConfig = {
    apiKey: "AIzaSyBeqLwCIJWWMHQ6UITxs_7pkkgthU5etkg",
    authDomain: "unico-qr.firebaseapp.com",
    databaseURL: "https://unico-qr.firebaseio.com",
    projectId: "unico-qr",
    storageBucket: "unico-qr.appspot.com",
    messagingSenderId: "553511716073",
    appId: "1:553511716073:web:3f36db3d75acc6a50b5c78"
};

firebase_app.initializeApp(firebaseConfig);


export const auth = firebase_app.auth();

export const devices = firebase_app.firestore().collection("devices_collection");

export const remoteConfig = firebase_app.remoteConfig();
