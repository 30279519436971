import React, {useState} from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import InputBase from "@material-ui/core/es/InputBase/index";
import {Eye, EyeOff, Search} from 'react-feather/dist/index';
import InputAdornment from '@material-ui/core/InputAdornment/index';
import {IconButton} from "@material-ui/core";
import classNames from "classnames";
import * as PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import Collapse from "@material-ui/core/Collapse";
import Text from "../DataDisplay/Text";
import Icon from 'react-eva-icons';
import useTheme from "@material-ui/core/styles/useTheme";
import {Edit2} from "react-feather";

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: "12px 16px",
    },
    input: {
        width: "100%",
        color: "black",
        background: "rgb(255,255,255)",
        border: "1.5px solid rgb(244, 244, 244,1.0)",
        borderRadius: "0px",
        lineHeight: "1.5",
        boxSizing: "border-box",
        fontFamily: "'Open Sans', sans-serif !important",
        fontWeight: "600",
        flexGrow: "1",
        margin: "8px 0px",
        boxShadow: "0px 6px 24px -12px rgba(10,10,10,0.0)",
        fontSize: "14px",
        height: "48px !important",
        transition: theme.transitions.create([ "box-shadow", "border", "background" ], {
            easing: theme.transitions.easing.easeInOut,
            duration: "0.5s",
        })
    },
    darkInput:{
        background: "rgb(36,36,36)",
        border: "1.5px solid rgb(65,65,65,0.9)",
        color: "white",
        '&:hover': {
            transition: "0.5s",
            background: "rgb(40,40,40)"
        }
    },

    focus: {
        transition: theme.transitions.create([ "border", "box-shadow", "background" ], {
            easing: theme.transitions.easing.easeInOut,
            duration: "0.3s",
        }),
        border: "1.5px solid rgb(244, 244, 244)",
        // boxShadow: "0px 0px 32px -18px rgb(53, 191, 65,1.0)",
    },
    focusDark: {
        transition: theme.transitions.create([ "border", "box-shadow", "background" ], {
            easing: theme.transitions.easing.easeInOut,
            duration: "0.3s",
        }),
        background: "rgb(40,40,40)",
        border: "1.5px solid rgb(90,90,90,1.0)",
        // boxShadow: "0px 0px 32px -18px rgb(53, 191, 65,1.0)",
    },

    background: {
        background: "rgb(250,250,250) !important"
    },
    backgroundDark: {
        background: "rgb(40,40,40) !important"
    },

    fullHeight: {
        height: "100% !important",
    },
    textField: {
        height: "unset !important",
        padding: "16px 16px",
    },
    borderLess: {
        border: "0",
        borderRadius: "0px",
    },
    bigFont: {
        fontSize: "20px"
    },
    iconSize: {
        width: "18px",
        height: "18px",
        [theme.breakpoints.down('md')]: {
            width: "14px",
            height: "14px",
        },
    },
    errorBorder: {
        border: "1.5px solid rgb(244, 0, 0, 0.3)",
        boxShadow: "none"
    },
    errorColor: {
        color: "rgb(198,53,89)",
    },
    disabled: {
        color: "rgb(80,80,80)"
    },
    disabledDark: {
        color: "rgb(200,200,200)"
    },
    editable: {
        padding: "12px 16px",
        background: "transparent",
        border: "none",
        cursor: "pointer !important"
    }
}));

function BaseInput ({ defaultValue, autoComplete, value, type, placeholder, onChange, style, name, multiline, max, autoFocus, rows, fullHeight, bigFont, disabled, error, borderLess, required, rootStyle, errorStyle, label, editable }) {

    const classes = useStyles();
    const theme = useTheme();

    let dark = theme.brightness === "dark";

    const [view, setView] = useState(false);
    const [focus, setFocus] = useState(false);

    const handleSwitchView = (e) => {
        e.preventDefault();
        setView(!view);
    };

    const handleFocus = e => {
        if (e)
            e.preventDefault();
        setFocus(!focus);
    };

    return (
        <div
            style={{ position: "relative", flexGrow: "1", ...rootStyle }}
            className={classNames(
                {[classes.fullHeight] : fullHeight}
            )}
        >
            <InputBase
                autoComplete={autoComplete}
                defaultValue={defaultValue}
                rows={rows}
                autoFocus={autoFocus}
                rowsMax={max}
                onFocus={handleFocus}
                onBlur={handleFocus}
                multiline={multiline}
                classes={{
                    input: editable ? classes.editable : classes.padding,
                    disabled: classes["disabled"+ (dark ? "Dark" : "")]
                }}
                className={classNames(
                    {[classes.input] : true},
                    {[classes.darkInput] : dark},
                    {[classes["background"+ (dark ? "Dark" : "")]]: (value && value.length > 0) || (error && error!=="")},
                    {[classes.textField] : multiline},
                    {[classes.fullHeight] : fullHeight},
                    {[classes.bigFont] : bigFont},
                    {[classes["focus"+ (dark ? "Dark" : "")]] : focus && !borderLess},
                    {[classes.borderLess] : borderLess},
                    {[classes.errorBorder] : error && error!==""},
                    {[classes.errorColor] : (value && value.length > 0) && error && error!==""},
                    {[classes.editable] : editable}
                )}
                // value={value}
                name={name}
                type={!view ? type : "text"}
                placeholder={placeholder}
                onChange={onChange}
                style={{
                    ...style
                }}
                required={required}
                disabled={disabled || editable}
                startAdornment={
                    <InputAdornment position="start">
                        <div style={{ transform: 'translate(12px,2px)' }}>
                            <Icon name={"lock-outline"} fill={focus ? theme.palette.primary.main : "rgb(150,150,150)"}
                                 size={"medium"}/>
                        </div>
                    </InputAdornment>
                }
                endAdornment={
                    (() => {
                        switch (type) {
                            case "password":
                                return <InputAdornment position="end">
                                    <IconButton onClick={handleSwitchView} style={{ borderRadius: "40%", padding: "8px", marginRight:"10px" }} onFocus={handleFocus} onBlur={handleFocus} tabindex="-1">
                                        {
                                            !view ?
                                                <EyeOff className={classes.iconSize} color={"rgb(190,190,190)"} width={20}/>
                                                :
                                                <Eye className={classes.iconSize} color={theme.palette.primary.main} width={20}/>
                                        }

                                    </IconButton>
                                </InputAdornment>;
                            case "search":
                                return <InputAdornment position="end">
                                    <IconButton style={{ borderRadius: "40%", padding: "8px", color: "#004cff" }} type={"submit" }>
                                        <Search className={classes.iconSize}/>
                                    </IconButton>
                                </InputAdornment>;
                            case "editable":
                                return <IconButton onClick={handleSwitchView} style={{ borderRadius: "40%", padding: "8px", marginRight:"10px" }} onFocus={handleFocus} onBlur={handleFocus} >
                                    <Edit2 className={classes.iconSize} color={"grey"} width={20}/>
                                </IconButton>;
                            default:
                                return null;
                        }
                    })()
                }
            />
            <Collapse in={error!==undefined && error !== "" && error!=="empty"} style={{ width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: '8px', ...errorStyle }}>
                    <Icon name={"alert-triangle"} size={"small"} fill={"rgb(234,84,105)"}/>
                    <Text variant={"caption"} style={{color:"rgb(198,53,89)", marginLeft: "12px", marginBottom: "2px", minHeight: "12px", textTransform:"none"}} >
                        {error}
                    </Text>
                </div>
            </Collapse>
        </div>
    );
}



function InputField (props) {

    return (
        (() => {
            switch (props.type) {
                case "tel" :
                    return  <InputMask
                        {...props}
                        value={props.value}
                        onChange={props.onChange}
                        mask={"(999) 999-9999"}
                        maskChar={null}
                    >
                        {(inputProps) =>
                            <BaseInput
                                {...inputProps}
                                type={"tel"}
                            />
                        }
                    </InputMask>;
                default :
                    return <BaseInput {...props}/>
            }
        })()

    );
}

InputField.propTypes = {
    dark: PropTypes.bool,
    defaultValue: PropTypes.any,
    value: PropTypes.any,
    type: PropTypes.oneOf(['text', 'number', 'password', 'search', 'email', 'tel', 'editable']),
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    name: PropTypes.string.isRequired,
    multiline: PropTypes.bool,
    max: PropTypes.number,
    autoFocus: PropTypes.bool,
    prefix: PropTypes.any,
    rows: PropTypes.number,
    required: PropTypes.bool,
    editable: PropTypes.bool,
    fullHeight: PropTypes.bool,
    bigFont: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.any,
    borderLess: PropTypes.bool,
    rootStyle: PropTypes.object,
    errorStyle: PropTypes.object,
    label: PropTypes.string,
    autoComplete: PropTypes.string
};

export default InputField;
