import * as React from 'react';
import Typography from "@material-ui/core/es/Typography";
import classNames from "classnames";
import {makeStyles} from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
    base: {
        zIndex: "1250",
        color: "rgb(40,40,40)",
        fontFamily: "'Open Sans', sans-serif"
    },
    h1: {
        lineHeight: "normal",
        fontSize: "55px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "40px"
        },
        [theme.breakpoints.down('md')]: {
            fontSize: "32px"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "28px"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "20px"
        },
    },
    h2: {
        fontSize: "40px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "30px"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "26px"
        },
    },
    h3: {
        fontSize: "32px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "28px"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "24px"
        },
    },
    h4: {
        fontSize: "32px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "26px"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "22px"
        },
    },
    h5: {
        fontSize: "26px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "22px"
        },
    },
    body1: {
        fontSize: "18px",
        lineHeight: "22px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px"
        },
    },
    body2: {
        letterSpacing: "0",
        fontSize: "15px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "15px"
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px"
        },
    },
    overline: {
        fontSize: "11px",
        fontWeight:"700",
        color: 'grey',
        lineHeight: '16px',
        letterSpacing: "2px",
        [theme.breakpoints.down('lg')]: {
            fontSize: "9px"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "9px",
            fontWeight:"700",
        },
    },
    caption: {
        fontSize: "14px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "12px"
        },
        letterSpacing: 0
    },
    xl: {
        fontSize: "40px",
        textTransform: "uppercase"
    },
    navigation: {
        alignItems: "center",
        height: "100%",
        display: "flex",
        // padding: "0 24px"
    },
    regular: {
        fontWeight: "400"
    },
    medium: {
        fontWeight: "600"
    },
    bold: {
        fontWeight: "700"
    },
}));

function Text ({variant, noWrap, children, style, medium, bold, navigation, xl, selectable=true, onMouseEnter, onMouseLeave, component, regular}) {
    const classes = styles();

    return (
        <Typography component={component}
                    variant={variant}
                    noWrap={noWrap}
                    className={
                        classNames(
                            { [classes.base] : true},
                            { [classes[variant]]: true, [classes.navigation]: navigation, [classes.xl]: xl },
                            { [classes.bold]: bold },
                            { [classes.medium]: medium },
                            { [classes.regular]: regular }
                        )
                    }
                    style={{ userSelect: selectable ? "unset" : "none", ...style }}
                    onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}
                    classes={{
                        root: classes[variant],
                        overline: classes.overline,
                        h1: classes.h1,
                        h2: classes.h2,
                        h3: classes.h3,
                        h4: classes.h4,
                        h5: classes.h5,
                        body1: classes.body1,
                        body2: classes.body2,
                        caption: classes.caption,
                    }}

        >
            {children}
        </Typography>
    );
}

export default Text ;
