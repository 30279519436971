import React from 'react'
import {Switch, Route} from 'react-router-dom'
import Home from "../../pages/Home/Home";
import Action from "../../pages/Action/Action";
import DeviceDetails from "../../pages/ViewDevice/ViewDevice";

const OpenRoute = ({ component: Component, props: cProps, ...rest }) => (
    <Route {...rest} render={(props) => (
        <Component {...props} {...cProps} />
    )} />
);


const RouterSwitch = ({ appProps }) => (
    <main>
        <Switch>
            <OpenRoute exact path='/' component={Home} props={appProps}/>

            <OpenRoute exact path='/view-device' component={DeviceDetails} props={appProps}/>

            <OpenRoute exact path='/action' component={Action} props={appProps}/>
        </Switch>
    </main>
);

export default RouterSwitch

