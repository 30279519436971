import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Switch} from "react-router-dom";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Switch>
                <Route path='/' component={App}/>
            </Switch>
        </BrowserRouter>
    </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
