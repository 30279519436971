import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Text from "../DataDisplay/Text";
import {Link} from "react-router-dom";
import ButtonBase from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    primary: {
        zIndex: "1250",
        position: "relative",
        display: "inline-flex",
        background: "linear-gradient(rgb(14, 155, 214), rgb(50, 110, 253))",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderRadius:"0px",
        padding:"16px 0px",
        border: "none",
        color: "white"
    },
    iconSize: {
        width: "16px",
        height: "16px"
    },
    loading: {
        color: "white"
    },
    rippleRoot: {
        filter: "blur(60px)",
        background: "white"
    }
}));

function RootElement ({onClick, children, type="button", disabled, loading}) {
    const classes = useStyles();

    // const [pressed, setPressed] = useState(false);
    // const [hover, setHover] = useState(false);

    return <ButtonBase
        disableRipple
        disableTouchRipple
        type={type}
        disabled={disabled  || loading}
        className={classes.primary}
        // onFocus={() => setHover(true)}
        // onBlur={() => setHover(false)}
        // onMouseUp={() => setPressed(false)}
        // onMouseDown={() => setPressed(true)}
        onClick={onClick}
        // onMouseLeave={() => setPressed(false)}
        // onTouchStart={() => setPressed(true)}
        // onTouchEnd={() => setPressed(false)}
        // onTouchCancel={() => setPressed(false)}
        // onMouseOver={() => setHover(true)}
        // onMouseOut={() => setHover(false)}
    >
        <Text selectable={false} style={{ color: "white", opacity:  (disabled || loading) ? "0.5" : "1.0" }} bold variant={"body2"}>{children}</Text>
        <div style={{ marginLeft: "8px", height: "16px", transform: `translateX(5px)`, transition: "1.5s", transitionTimingFunction: "cubic-bezier(.15,.94,.08,1.22)" }}>
            <CircularProgress classes={{ colorPrimary: classes.loading }} size={14} thickness={7}
                              style={{
                                  position: "absolute",
                                  transition: "0.3s",
                                  left: "0",
                                  transitionTimingFunction: "cubic-bezier(.15,.94,.08,1.22)",
                                  opacity: loading ? 1 : 0
                              }}/>
        </div>
    </ButtonBase>
}

function Button ({to, onClick, children, type, disabled, loading, arrow}) {

    return to ?
        (to.includes("http") ?
                <a href={to} rel="noopener noreferrer" target={"_blank"}>
                    <RootElement type={type} disabled={disabled} loading={loading} children={children} arrow={arrow}/>
                </a>
                :
                <Link to={to}>
                    <RootElement type={type} disabled={disabled} loading={loading} children={children} arrow={arrow}/>
                </Link>
        )
        :
        <RootElement type={type} disabled={disabled} loading={loading} children={children} onClick={onClick} arrow={arrow}/>
}

Button.propTypes = {
    children: PropTypes.string.isRequired,
    to: PropTypes.string,
    onClick: PropTypes.func,
    arrow: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    type: PropTypes.string
};

export default Button;