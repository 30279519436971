import React from "react";
import Collapse from "@material-ui/core/Collapse";
import Text from "../../components/DataDisplay/Text";
import Icon from "react-eva-icons";

function FormErrorText({error, showError, style}) {
    let errorValue;

    if (typeof error === "string")
        errorValue=error;
    else
        errorValue=error.text;

    return (
        <Collapse in={showError && errorValue!==undefined && errorValue!==""} style={{ width: "100%", ...style }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: '8px 0'}}>
                <Icon name={"alert-triangle"} size={"small"} fill={"rgb(234,84,105)"}/>
                <Text variant={"caption"} style={{color:"rgb(198,53,89)", marginLeft: "12px", marginBottom: "2px", minHeight: "12px", textTransform:"none"}} >
                    {errorValue}
                </Text>
            </div>
        </Collapse>
    )
}

export default FormErrorText;