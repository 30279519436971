import React from 'react';
import AppRoutes from "./components/AppRoutes/AppRoutes";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

const MuiTheme = createMuiTheme({
    typography: {
        useNextVariants: true,
    },
    brightness: "light",
    palette: {
        primary: {
            main: "rgb(50, 110, 253)"
        },
        text: "rgb(30,30,30)",
        disabled: "rgb(90,90,90)",
        scaffoldBackground: "rgb(245,245,252)",
        background: "rgb(255,255,255)"
    }
});

function App() {
  return (
    <MuiThemeProvider theme={MuiTheme}>
      <AppRoutes/>
    </MuiThemeProvider>
  );
}

export default App;
