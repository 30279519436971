import * as React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import * as queryString from'query-string';
import CircularProgress from "@material-ui/core/CircularProgress";
import {devices, remoteConfig} from '../../config/config';
import {useEffect, useState} from "react";
import Text from "../../components/DataDisplay/Text";
import Icon from "react-eva-icons";
import Collapse from "@material-ui/core/Collapse";
import useTheme from "@material-ui/core/styles/useTheme";
import {isAndroid, isIOS, isMobile} from 'react-device-detect';
import {reactLocalStorage} from 'reactjs-localstorage';
import UnicoLogo from "../../assets/unico.png";
import TraneLogo from "../../assets/trane.png";
import BenshawLogo from "../../assets/benshaw.png";
import AppStore from "../../assets/app_store.jpeg";
import PlayStore from "../../assets/google_play.png";
import ScanBG from "../../assets/scan.png";
import {useMediaQuery} from "@material-ui/core";
import axios from 'axios';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    background: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: '100vh',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
        },
    },
    card: {
        maxWidth: '450px',
        background: "white",
        margin: '24px auto',
        borderRadius: '6px',
        border: '1.5px solid rgb(240,240,240)',
    },
    section: {
        padding: '20px 24px',
        display: 'flex',
        flexDirection: 'column'
    },
    loading: {
        color: theme.palette.primary.main
    },
    listItem: {
        display: 'flex',
        padding: '12px 16px',
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: 'center',
        cursor: "pointer",
        transition: "0.3s",
        "&:hover": {
            background: "rgb(253,253,253)"
        }
    },
    dialog: {
        bottom: 0
    },
    dialogRoot: {
        alignItems: 'flex-end',
        [theme.breakpoints.up('sm')]: {
            height: "auto",
            marginTop: "25%",
            alignItems: "center",
        }
    },
    paper: {
        margin: "0",
        width: "100%",
        borderTopLeftRadius: "14px",
        borderTopRightRadius: "14px",
        boxShadow: "unset",
        [theme.breakpoints.up('sm')]: {
            borderRadius: "14px",
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DeviceDetails({location}) {

    const classes = useStyles();
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    let [error, setError] = useState("");
    let [loading, setLoading] = useState(true);
    let [device, setDevice] = useState(null);
    let [mobileDialog, setMobileDialog] = useState(false);
    let [deepLink, setDeepLink] = useState("");

    useEffect(() => {
        if (isMobile)
            generateDynamicLink();

        let model; let serial;

        let queryData = queryString.parse(location.search);

        if (queryData.modelId) model = queryData.modelId;
        else model = queryData.model;

        if (queryData.serialNo) serial = queryData.serialNo;
        else serial = queryData.serialNo;


        async function getDevice(model, serial) {
            if (model && serial) {
                let doc = await devices.doc(model).get();
                if (doc.exists) {
                    let device = doc.data();
                    device.serialNumber = serial;
                    if (device.documents===null || device.documents===undefined)
                        device.documents = [];
                    device.documents.push({
                        typeSelector: 'Prop 65 and Unico Technology LLC',
                        url: 'https://firebasestorage.googleapis.com/v0/b/unico-qr.appspot.com/o/CA%20Prop%2065%20Warning.pdf?alt=media&token=216ed985-5209-4ac7-84ec-f5464c2a441f'
                    });
                    setDevice(device);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setError("Device not found.")
                }
            } else {
                setLoading(false);
                setError("URL is invalid.")
            }
        }
        getDevice(model, serial);

    }, [location]);

    const generateDynamicLink = async () => {
        let mobile = await reactLocalStorage.get("mobile", true);
        console.log(mobile);
        await setMobileDialog(mobile===true || mobile==="true");
        await remoteConfig.fetchAndActivate();
        let appPrefix = remoteConfig.getValue("dynamic_link_prefix").asString();
        const linkInfo = {
            "dynamicLinkInfo": {
                "domainUriPrefix": appPrefix,
                "link": window.location.href,
                "androidInfo": {
                    "androidPackageName": "com.unicous.qrScanner",
                },
                "iosInfo": {
                    "iosBundleId": "edu.uwp.appfactory.unicoReader",
                    "iosIpadBundleId": "edu.uwp.appfactory.unicoReader",
                    "iosAppStoreId": "1490386647"
                },
                "navigationInfo": {
                    "enableForcedRedirect": true,
                },
            },
            "suffix": {
                "option": "SHORT"
            }
        };
        axios.post("https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBeqLwCIJWWMHQ6UITxs_7pkkgthU5etkg", linkInfo)
        .then(function (response) {
            setDeepLink(response.data["shortLink"]);
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    return <div className={classes.background}>
        {isMobile &&
            <Grid container justify={'center'} component={'div'} style={{ borderBottom: '2px solid rgb(230,230,230)', position: 'fixed', background: "rgb(245,245,245)", zIndex: '1300', top: 0 }}>
                <Grid item xs={11} sm={10} md={8} lg={5} xl={5} component={'div'}>
                    <div className={classes.card} style={{ background: "transparent", border: 'none', width: "100%", flexDirection: "row", display: 'flex', justifyContent: "space-between", boxSizing :"border-box" }}>
                        <div style={{ width: "180px" }}>
                            <Text variant={"body2"} medium >Save your Scan History with our app. </Text>
                        </div>
                        <a href={deepLink}>
                            <div style={{
                                padding: "8px 24px",
                                background: "linear-gradient(rgb(14, 155, 214), rgb(50, 110, 253)",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <Text variant={"body2"} bold style={{ color: 'white' }}>Use App</Text>
                            </div>
                        </a>
                    </div>
                </Grid>
            </Grid>
        }
        {isMobile &&
            <Dialog
                open={mobileDialog}
                className={classes.dialog} maxWidth={"xs"}
                TransitionComponent={Transition}
                classes={{
                    scrollPaper: classes.dialogRoot,
                    paperScrollPaper: classes.paper
                }}
            >
                <Grid container justify={'center'} component={'div'} style={{ background: "white", paddingBottom: "24px", paddingTop: "8px" }}>
                    <Grid item xs={11} sm={11} md={11} lg={10} xl={5} component={'div'} >
                        <div className={classes.card} style={{ background: "transparent", border: 'none', width: "100%", flexDirection: "column", display: 'flex', justifyContent: "space-between", boxSizing :"border-box" }}>
                            <div style={{ justifyContent: "flex-end", display: 'flex', marginBottom: '12px' }}>
                                <div onClick={() => {setMobileDialog(false)}} style={{ background: "rgb(240,240,240)", borderRadius: "50%", padding: '5px 5px 0 5px', marginRight: "12px" }}>
                                    <Icon name={'close-outline'} fill={"black"}/>
                                </div>
                            </div>
                            <div style={{ justifyContent: "center", display: 'flex', marginBottom: '12px' }}>
                                <img src={ScanBG} height={100} alt={"Unico QR"}/>
                            </div>
                            <Text variant={"h5"} bold style={{ lineHeight: "1", textAlign: "center" }}>For a better experience <br/> use our app. </Text>
                            <a href={deepLink} style={{ display: "flex" }}>
                                <div style={{ margin: "24px auto" }}>
                                    {isIOS &&
                                        <img src={AppStore} height={65} alt={"Download Unico QR from App Store."}/>
                                    }
                                    {isAndroid &&
                                        <img src={PlayStore} height={65} alt={"Download Unico QR from Play Store."}/>
                                    }
                                </div>
                            </a>
                            <div onClick={() => {
                                setMobileDialog(false);
                                reactLocalStorage.set("mobile", false);
                            }} style={{ padding: "12px" }}>
                                <Text variant={"body2"} medium style={{ lineHeight: "1", textAlign: "center", textDecoration: "underline", color: "grey" }}>Don't show again</Text>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Dialog>
        }
        {
            isMobile && mdDown &&
                <div style={{ marginBottom: "88px" }}/>
        }
        <Grid container justify={'center'} component={'div'}>
            <Grid item xs={11} sm={10} md={8} lg={5} xl={5} component={'div'} >
                <div className={classes.card}>
                    <Collapse in={loading} unmountOnExit>
                        <div className={classes.section} style={{alignItems: 'center'}}>
                            <CircularProgress size={18.0} thickness={10.0} classes={{ root: classes.loading }}/>
                        </div>
                    </Collapse>
                    <Collapse in={error!==""} unmountOnExit>
                        <div className={classes.section} style={{ alignItems: 'center' }}>
                            <Icon name={"alert-triangle"} size={"large"} fill={'rgb(234,84,105)'}/>
                            <Text variant={"body2"} style={{ color:'rgb(198,53,89)', marginLeft: '8px' }} medium>{error}</Text>
                        </div>
                    </Collapse>
                    <Collapse in={device!==null && !loading} unmountOnExit>
                        <div className={classes.section} style={{ borderBottom: '1.5px solid rgb(240,240,240)', display: 'block', background: "rgb(246, 241, 236)" }}>
                            {device!=null && device.brand==="U" && <img src={UnicoLogo} alt={"Unico Logo"} height={mdDown ? 80 : 100}/>}
                            {device!=null && device.brand==="B" && <img src={BenshawLogo} alt={"Trane Logo"} height={64}/>}
                            {device!=null && device.brand==="T" && <img src={TraneLogo} alt={"Benshaw Logo"} height={64}/>}
                        </div>
                        <div className={classes.section} style={{ borderBottom: '1.5px solid rgb(240,240,240)' }}>
                            <Text variant={'overline'}>SERIAL NUMBER</Text>
                            <Text variant={'body1'} regular>{device!=null ? device.serialNumber : ""}</Text>
                        </div>
                        <div className={classes.section} style={{ borderBottom: '1.5px solid rgb(240,240,240)' }}>
                            <Text variant={'overline'}>PRODUCT LINE</Text>
                            <Text variant={'body1'} regular>{device!=null ? device.productLine : ""}</Text>
                        </div>
                        <div className={classes.section} style={{ borderBottom: '1.5px solid rgb(240,240,240)' }}>
                            <Text variant={'overline'}>PRODUCT MODEL</Text>
                            <Text variant={'body1'} regular>{device!=null ? device.productModel : ""}</Text>
                        </div>
                        <div className={classes.section}>
                            <Text variant={'overline'}>MANUALS</Text>
                            <div style={{ marginTop: "16px", border: "1.5px solid rgb(245,245,245)" }}>
                                {
                                    device!=null && device.documents!==null && device.documents.length > 0 &&
                                        device.documents.map((document, index) => {
                                            return <a href={document.url!=null ? document.url : ""} key={index}>
                                                <div className={classes.listItem} style={{ borderBottom: (index===device.documents.length-1) ? "unset" : '1.5px solid rgb(245,245,245)' }}>
                                                    <Text variant={'body2'} medium style={{ color: theme.palette.primary.main }}>{document.typeSelector!=null ? document.typeSelector : ""}</Text>
                                                    <Icon name={"chevron-right"} size={"medium"} fill={'rgb(80,80,80)'}/>
                                                </div>
                                            </a>
                                        })

                                }
                            </div>
                        </div>
                    </Collapse>
                </div>
                <div style={{ display: 'flex', justifyContent: "center" }}>
                    <a href={"http://unicous.com"} style={{ padding: "12px" }}>
                        <Text variant={"body2"} medium style={{ lineHeight: "1", textAlign: "center", textDecoration: "underline", color: "grey" }}>Unico</Text>
                    </a>
                    <a href={"http://unicous.com/service"} style={{ padding: "12px" }}>
                        <Text variant={"body2"} medium style={{ lineHeight: "1", textAlign: "center", textDecoration: "underline", color: "grey" }}>Unico Service</Text>
                    </a>
                </div>
            </Grid>
        </Grid>
    </div>
}

export default DeviceDetails;